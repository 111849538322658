'use strict';

const websiteListeners = {
	/**
	 * Run all callbacks with current window scrollTop parameter on function init and on every 'scroll' event.
	 *
	 * @param {Array} scrollEventCallbacks - Array of window scroll event callbacks.
	 */
	windowScroll: ( scrollEventCallbacks ) => {
		let timeoutID = null;

		// Init listeners.
		const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		scrollEventCallbacks.forEach( cb => cb( windowScrollTop ) );

		window.addEventListener( 'scroll', () => {
			if ( timeoutID ) {
				clearTimeout( timeoutID );
			}

			timeoutID = setTimeout( () => {
				let windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
				scrollEventCallbacks.forEach( cb => cb( windowScrollTop ) );
				timeoutID = null;
			}, 10 );
		} );
	},
	/**
	 * Run all callbacks with current window width parameter on listener function init and on every 'resize' event.
	 *
	 * @param {Array} resizeEventCallbacks - Array of window resize event callbacks.
	 */
	windowResize: ( resizeEventCallbacks ) => {
		let rtime;
		let timeout = false;
		const delta = 200;

		// Init listeners.
		resizeEventCallbacks.forEach( cb => cb( window.innerWidth, window.innerHeight ) );

		function resizeend() {
			if ( new Date() - rtime < delta ) {
				setTimeout( resizeend, delta );
			} else {
				timeout = false;
				resizeEventCallbacks.forEach( cb => cb( window.innerWidth, window.innerHeight ) );
			}
		}

		window.addEventListener( 'resize', () => {
			rtime = new Date();
			if ( timeout === false ) {
				timeout = true;
				setTimeout( resizeend, delta );
			}
		} );
	},
	/**
	 * Run all callbacks with current window width parameter on listener function init and on every 'resize' event.
	 *
	 * @param {Array} orientationchangeEventCallbacks - Array of window orientationchange event callbacks.
	 */
	windowOrientationchange: ( orientationchangeEventCallbacks ) => {
		window.addEventListener( 'orientationchange', () => {
			orientationchangeEventCallbacks.forEach( cb => cb( ( window.innerHeight > window.innerWidth ) ? 'portrait' : 'landscape' ) );
		} );
	}
};

export default websiteListeners;
