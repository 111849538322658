import { Promise } from 'es6-promise';

/**
 * Get components callbacks on taken listeners
 *
 * @export getEventsCallbacks
 * @param {Object} listeners -  Listeners collection.
 * @param {Object} componentsAPI - Components API collection
 * @returns {Promise} - Promise with components events callbacks as results.
 */
export default function getEventsCallbacks( listeners, componentsAPI ) {
	let eventsCallbacks = [];

	for ( let componentName in componentsAPI ) {
		const componentAPI = componentsAPI[ componentName ];

		for ( let listenerName in listeners ) {
			const targetCallbackName = 'on' + listenerName.charAt( 0 ).toUpperCase() + listenerName.substr( 1 );
			let componentListenerCallback = componentAPI[ targetCallbackName ];

			if ( !componentListenerCallback ) {
				continue;
			}

			if ( !eventsCallbacks[ listenerName ] ) {
				eventsCallbacks[ listenerName ] = [];
			}
			eventsCallbacks[ listenerName ].push( componentListenerCallback );
		}
	}

	return new Promise( ( resolve ) => {
		resolve( eventsCallbacks );
	} );
}
