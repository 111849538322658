import { classToggler, mobile } from '../_helpers';
const { isDesktop, isPhoneSize } = mobile;

/**
 * Top menu component.
 */
const Menu = () => {
	const menuElement = document.querySelector( '.menu' );

	if ( !menuElement ) {
		return {};
	}

	const originMenuTopOffset = menuElement.offsetTop;
	const boundClassToggler = classToggler.bind( undefined, menuElement );
	const legalMenu = menuElement.querySelector( '.menu-product--legal' );

	/**
	 * Set tabindex=-1 to the all context menu links when menu isn't visible on the page.
	 * This is improving travelling on the page by keyboard.
	 */
	function toggleFocusableState() {
		const menuLinks = legalMenu.querySelectorAll( 'a' );
		const focusable = legalMenu.classList.contains( 'menu-product--focusable' );


		if ( !isDesktop() && !focusable ) {
			legalMenu.classList.add( 'menu-product--focusable' );
			Array.prototype.forEach.call( menuLinks, ( link ) => link.removeAttribute( 'tabindex' ) );
		} else if ( isDesktop() && focusable ) {
			legalMenu.classList.remove( 'menu-product--focusable' );
			Array.prototype.forEach.call( menuLinks, ( link ) => link.setAttribute( 'tabindex', '-1' ) );
		}
	}

	return {
		init: legalMenu ? toggleFocusableState : null,
		onWindowResize: legalMenu ? toggleFocusableState : null,
		onWindowScroll: isPhoneSize() ? null : ( windowScrollTop ) =>
			boundClassToggler( windowScrollTop >= originMenuTopOffset, 'menu--small' )
	};
};

export default Menu;
