'use strict';

/**
 * FloatingPanel place element based on co-ordinates taken from dataset. Change state of element depends on window top position.
 *
 * @returns {Object} - Window events listeners callback and init function.
 */
const FloatingPanel = () => {
	const floatingPanelElement = document.querySelector( '.floating-panel' );

	if ( !floatingPanelElement || !floatingPanelElement.hasAttribute( 'data-relative' ) ) {
		return {};
	}

	const relativeElementClass = floatingPanelElement.getAttribute( 'data-relative' );
	const nextToElementClass = floatingPanelElement.getAttribute( 'data-next-to' );
	const relativeElements = document.querySelectorAll( relativeElementClass );
	const nextToElement = document.querySelector( nextToElementClass );
	let windowHeight = window.innerHeight;
	let originFloatingElementTopValue = floatingPanelElement.style.top;

	if ( !relativeElements.length ) {
		return {};
	}

	const requestedOffsetTop = floatingPanelElement.hasAttribute( 'data-top-offset' ) ?
		Number( floatingPanelElement.getAttribute( 'data-top-offset' ) ) : 0;

	const lastRelativeElement = relativeElements[ relativeElements.length - 1 ];
	const originRelatedElementHeight = lastRelativeElement.offsetHeight;
	let floatingPanelOffsetTop = floatingPanelElement.offsetTop;
	let floatingPanelHeight = floatingPanelElement.offsetHeight;
	let floatingPanelEndPoint = floatingPanelHeight + floatingPanelOffsetTop;
	let relativeElementEnd = lastRelativeElement.offsetTop + originRelatedElementHeight;

	/**
	 * Set start panel position and update it when window is resized.
	 *
	 * @param {Number} innerWidth - Window inner width value.
	 * @param {Number} innerHeight - Window inner height value.
	 */
	function _setStartPosition( innerWidth = null, innerHeight = null ) {
		let rightOffset = floatingPanelElement.getAttribute( 'data-right-offset' );

		if ( rightOffset ) {
			floatingPanelElement.style.left = lastRelativeElement.offsetLeft - floatingPanelElement.offsetWidth - rightOffset + 'px';
		}

		// Check if this function call is triggered on init or on window.resize.
		if ( ( !innerWidth || windowHeight !== innerHeight ) && nextToElement ) {
			let topOffset = nextToElement.offsetTop;
			floatingPanelElement.style.top = topOffset + 'px';
			originFloatingElementTopValue = topOffset + 'px';
			floatingPanelOffsetTop = topOffset;

			if ( windowHeight !== innerHeight ) {
				windowHeight = innerHeight;
			}
		}
	}

	return {
		init: _setStartPosition,
		/**
		 * Update floating panel state and position depends on window.scrollTop value.
		 *
		 * @param {Number} windowScrollTop - Current window scroll top position.
		 */
		onWindowScroll: ( windowScrollTop ) => {
			let isFixed = floatingPanelElement.classList.contains( 'floating-panel--fixed' );

			// Check if panel state should be transformed to fixed.
			if ( windowScrollTop > floatingPanelOffsetTop - requestedOffsetTop ) {
				if ( !isFixed ) {
					floatingPanelElement.classList.add( 'floating-panel--fixed' );
					floatingPanelElement.style.top = requestedOffsetTop + 'px';
				}
			} else if ( isFixed ) {
				floatingPanelElement.classList.remove( 'floating-panel--fixed' );
				floatingPanelElement.style.top = originFloatingElementTopValue || null;
			}

			// Relative element could change after page load, so it should be updated.
			if ( lastRelativeElement.offsetHeight + lastRelativeElement.offsetTop !== relativeElementEnd ) {
				relativeElementEnd = lastRelativeElement.offsetHeight + lastRelativeElement.offsetTop;
			}

			// The same as above if offset height of floating panel different than before it should be also updated.
			if ( floatingPanelHeight !== floatingPanelElement.offsetHeight ) {
				floatingPanelHeight = floatingPanelElement.offsetHeight;
				floatingPanelEndPoint = floatingPanelHeight + floatingPanelOffsetTop;
			}

			const fromLimitPoint = relativeElementEnd - windowScrollTop - floatingPanelEndPoint;
			let isStatic = floatingPanelElement.classList.contains( 'floating-panel--static' );

			// Check if static state should be updated or not.
			if ( ( fromLimitPoint <= 0 && isStatic ) || ( fromLimitPoint > 0 && !isStatic ) ) {
				return;
			}

			// If panel isn't static and arive to limit point (end of related block) set static state,
			// leave this element in the same place as it is in this moment.
			if ( fromLimitPoint <= 0 && !isStatic ) {
				floatingPanelElement.style.top = `${ windowScrollTop + floatingPanelElement.offsetTop }px`;
				floatingPanelElement.classList.add( 'floating-panel--static' );

				return;
			}

			// Remove static state and set starting top position.
			floatingPanelElement.classList.remove( 'floating-panel--static' );
			floatingPanelElement.style.top = requestedOffsetTop ? `${requestedOffsetTop}px` : originFloatingElementTopValue;
		},
		/**
		 * Update position after window resize.
		 */
		onWindowResize: _setStartPosition
	};
};

export default FloatingPanel;
