'use strict';

/**
 * Create a collection of component API - keyed by component name.
 *
 * @export getAPICollection
 * @param {Object} components - Components collection.
 * @returns {Object} Component API collection.
 */
export default function getAPICollection( components ) {
	let componentsAPICollection = {};

	for ( let componentName in components ) {
		componentsAPICollection[ componentName ] = components[ componentName ]();
	}

	return componentsAPICollection;
}
