'use strict';

/**
 * Toggle class for specific condition.
 *
 * @exports classToggler
 * @param {Object} element - DOM element which will be the target for class toggle.
 * @param {Boolean} condition - Condition for add class.
 * @param {String} className - Name of state class.
 */
export default function classToggler( element, condition, className ) {
	if ( !element || typeof ( condition ) === 'undefined' || !className ) {
		return;
	}

	if ( condition ) {
		if ( !element.classList.contains( className ) ) {
			element.classList.add( className );
		}
	} else if ( element.classList.contains( className ) ) {
		element.classList.remove( className );
	}
};
