'use strict';

import Swiper from 'swiper';

const Slider = () => {
	const slider = document.querySelector( '.slider' );

	if ( !slider ) {
		return {};
	}

	return {
		init() {
			new Swiper( '.swiper-container', {
				pagination: '.swiper-pagination',
				paginationClickable: true,
				slidesPerView: 1,
				speed: 800,
				autoplay: 8000,
				loop: true,
				a11y: true
			} );
		}
	};
};

export default Slider;
