const deviceSizes = {
	mobile: 767,
	tablet: 1279
};

/**
 * @returns {Boolean} - True when window width is under predefined mobile device width limit.
 */
export function isPhoneSize() {
	return ( window.innerWidth <= deviceSizes.mobile );
}

/**
 * @returns {Boolean} - True when window width is under predefined tablet device width limit.
 */
export function isTabletSize() {
	return ( window.innerWidth > deviceSizes.mobile && window.innerWidth <= deviceSizes.tablet );
}

/**
 * @returns {Boolean} - True when window width is bigger than predefined tablet device width limit.
 */
export function isDesktop() {
	return ( window.innerWidth > deviceSizes.tablet );
}

/**
 * @returns {Boolean} - True when userAgent contains name of the common mobile devices.
 */
export function isMobile() {
	return /iPhone|iPad|iPod|Android/i.test( navigator.userAgent );
}
