'use strict';

import cookies from 'js-cookie';

const Slider = () => {
	const cookiesBox = document.querySelector( '.cookies-box' );

	if ( !cookiesBox || cookies.get( 'cookies-policy' ) ) {
		return {};
	}

	return {
		init() {
			cookiesBox.classList.add( 'cookies-box--active' );
			const cookiesAgreeButton = cookiesBox.querySelector( '.cookies-agree' );

			cookiesAgreeButton.addEventListener( 'click', () => {
				cookies.set( 'cookies-policy', 'true', { expires: 365 } );
				cookiesBox.classList.add( 'cookies-box--accepted' );
				setTimeout( () => cookiesBox.classList.remove( 'cookies-box--active',  'cookies-box--active' ), 600 );
			} );
		}
	};
};

export default Slider;