const Dropdown = () => {
	const togglers = document.querySelectorAll( '[data-toggle="dropdown"]' );

	if ( !togglers.length ) {
		return {};
	}

	const toggleDropdown = evt => {
		const trigger = evt.target || evt.srcElement;
		const { type, keyCode, which } = evt;
		const code = keyCode || which;
		const dropdownWrapper = trigger.parentNode;

		if ( type === 'keyup' && code !== 27 ) {
			return;
		}

		if ( !dropdownWrapper.classList.contains( 'dropdown' ) ) {
			// Click outside dropdown wrapper.
			const openedDropdown = document.querySelector( '[data-open="true"]' );
			if ( openedDropdown ) {
				openedDropdown.setAttribute( 'data-open', false );
				openedDropdown.querySelector( '[data-toggle="dropdown"]' ).setAttribute( 'expanded', false );
			}
			window.removeEventListener( 'click', toggleDropdown );
			window.removeEventListener( 'keyup', toggleDropdown );

			return;
		}

		const closeDropdownList = dropdownWrapper.getAttribute( 'data-open' ) ?
			Boolean( dropdownWrapper.getAttribute( 'data-open' ) === 'true' ) :
			false;

		if ( !closeDropdownList ) {
			Array.prototype.forEach.call( togglers, ( toggler ) => {
				toggler.parentNode.setAttribute( 'data-open', 'false' );
			} );
		}

		dropdownWrapper.setAttribute( 'data-open', !closeDropdownList );
		trigger.setAttribute( 'expanded', !closeDropdownList );

		if ( !closeDropdownList ) {
			window.addEventListener( 'click', toggleDropdown );
			window.addEventListener( 'keyup', toggleDropdown );
		} else {
			window.removeEventListener( 'click', toggleDropdown );
			window.removeEventListener( 'keyup', toggleDropdown );
		}
	};

	function activateDropdownTriggers() {
		Array.prototype.forEach.call( togglers, trigger => {
			trigger.addEventListener( 'click', toggleDropdown );
			trigger.parentNode.addEventListener( 'click', evt => evt.stopPropagation() );
		} );
	}

	return {
		init() {
			activateDropdownTriggers();
		}
	};
};

export default Dropdown;
