'use strict';

import axios from 'axios';

const UpdateSocials = () => {

  function getSocialStats() {
    if (!document.querySelector( '.site-content--cla' ) || !document.querySelectorAll( '.social__number' )){
      return;
    }

    const bustCache = '?' + new Date().getTime().toString().slice(0, -4);

    axios.get( 'https://ckeditor.com/cke4/social_stats' + bustCache )
      .then(function (response) {
        const data = response.data;
        const socialNumbers = document.querySelectorAll( '.social__number' );

        Array.prototype.forEach.call(socialNumbers, ( socialItem ) => {

          socialItem.innerHTML = data[socialItem.dataset.name];
        });
      })
      .catch(function (error) {});
  }

  return {
    init: getSocialStats
  };
};

export default UpdateSocials;
