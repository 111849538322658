'use strict';

/**
 * Calculate available space for context (product) links and put some of them to dropdown list if needed.
 *
 * @returns {Object} - Window events listeners callbacks and init function.
 */
const CollapsingMenu = () => {
	const menuElement = document.querySelector( '.menu' );

	if ( !menuElement ) {
		return {};
	}

	const menuListElement = menuElement.querySelector( 'nav.menu-product' );

	if ( !menuListElement ) {
		return {};
	}

	// When visible elements quantity is bigger than 3 then use start label when is lower then should display end.
	const TOGGLE_BUTTON_LABELS = {
		start: 'More',
		end: 'Menu'
	};
	const siteTitleElement = menuElement.querySelector( '.menu__page-title' );
	const moreElement = menuElement.querySelector( '.menu-more' );
	const menuItems = menuListElement.querySelectorAll( 'li' );
	const menuItemsOriginData = Array.prototype.map.call( menuItems, ( item ) => ( {
		el: item,
		width: item.offsetWidth
	} ) );

	const siteTitleStyle = siteTitleElement.currentStyle || window.getComputedStyle( siteTitleElement );
	let lastMenuItemsState = {};

	let totalSiteTitleWidth = siteTitleElement.offsetWidth;
	totalSiteTitleWidth += siteTitleStyle.paddingLeft ?
		Number( siteTitleStyle.paddingLeft.substring( 0, siteTitleStyle.paddingLeft.length - 2 ) ) : 0;
	totalSiteTitleWidth += siteTitleStyle.paddingRight ?
		Number( siteTitleStyle.paddingRight.substring( 0, siteTitleStyle.paddingRight.length - 2 ) ) : 0;

	// Hard coded because it's impossible to calculate if more list is not displayed.
	const moreElementWidth = 90;
	const menuOffset = totalSiteTitleWidth + moreElementWidth;

	/**
	 * Remove all children nodes.
	 *
	 * @param {Object} el - DOM element which should be cleaned up.
	 */
	function clearElement( el ) {
		while ( el.firstChild ) {
			el.removeChild( el.firstChild );
		}
	}

	/**
	 * Create map of menu items state with hidden status. Width value is taken from elements grabbed on start.
	 *
	 * @param {Array} items - Array of menu <li> elements.
	 * @param {Number} offset - Calculated space taken by menu title and all paddings.
	 * @returns {Array} - List of elements states.
	 */
	function getListItemsState( items, offset ) {
		// const itemsWidthSummary = Array.prototype.reduce.call( items, ( prev, next ) => prev.offsetWidth + next.offsetWidth, 0 );
		let availableSpace = window.innerWidth - offset;
		let hidden = false;

		return items.map( ( item ) => {
			const { el, width } = item;

			if ( !hidden ) {
				availableSpace -= width;

				if ( availableSpace < 0 ) {
					hidden = true;
				}
			}

			return {
				hidden,
				el,
				width
			};
		} );
	}

	/**
	 * Check if next state is different from the current one.
	 *
	 * @param {Array} prev - Previous array of menu items states.
	 * @param {Array} next - Next array of menu items state.
	 * @returns {Boolean} - TRUE when is the previous is different from the next.
	 */
	function isDifferentState( prev, next ) {
		if ( prev.length !== next.length ) {
			return true;
		}

		return prev.some( ( prevState, index ) => prevState.hidden !== next[ index ].hidden );
	}

	/**
	 * Check if previous state is different from the current, clear menu more list and product menu.
	 * Next refill them: hidden in menu more, not-hidden in product menu.
	 *
	 * @param {Array} itemsTargetState - Requested menu items state.
	 * @param {Object} mainMenuContainer - Container of product menu list.
	 * @param {Object} menuMoreContainer - Container of the rest menu list - place where are items for which there is no space in product menu.
	 * @returns
	 */
	function fillMenuMore( itemsTargetState, mainMenuContainer, menuMoreContainer ) {
		const shouldUpdateMenu = isDifferentState( lastMenuItemsState, itemsTargetState );

		if ( !shouldUpdateMenu ) {
			return;
		}

		lastMenuItemsState = itemsTargetState;
		const menuMoreList = menuMoreContainer.querySelector( 'ul' );
		const mainMenu = mainMenuContainer.querySelector( 'ul' );
		const toggleButton = menuMoreContainer.querySelector( 'button' );

		mainMenuContainer.classList.remove( 'menu-product--ready' );

		// Clear containers.
		clearElement( mainMenu );
		clearElement( menuMoreList );

		itemsTargetState.forEach( ( targetState ) => {
			const { hidden, el } = targetState;

			if ( !hidden ) {
				mainMenu.appendChild( el );
			} else {
				menuMoreList.appendChild( el );
			}
		} );

		if ( menuMoreList.hasChildNodes() && !menuMoreContainer.classList.contains( 'menu-more--active' ) ) {
			menuMoreContainer.classList.add( 'menu-more--active' );
			toggleButton.setAttribute( 'tabindex', 0 );
		} else if ( !menuMoreList.hasChildNodes() && menuMoreContainer.classList.contains( 'menu-more--active' ) ){
			menuMoreContainer.classList.remove( 'menu-more--active' );
			toggleButton.setAttribute( 'tabindex', -1 );
		}

		if ( mainMenu.childNodes.length >= 3 && toggleButton.textContent === TOGGLE_BUTTON_LABELS.end ) {
			clearElement( toggleButton );
			toggleButton.appendChild( document.createTextNode( TOGGLE_BUTTON_LABELS.start ) );
		} else if ( mainMenu.childNodes.length < 3 && toggleButton.textContent === TOGGLE_BUTTON_LABELS.start ) {
			clearElement( toggleButton );
			toggleButton.appendChild( document.createTextNode( TOGGLE_BUTTON_LABELS.end ) );
		}

		mainMenuContainer.classList.add( 'menu-product--ready' );
	}

	// Trigger fillMenuMore with target state.
	function setMenuMore() {
		fillMenuMore( getListItemsState( menuItemsOriginData, menuOffset ), menuListElement, moreElement );
	}

	return {
		init: setMenuMore,
		onWindowResize: setMenuMore
	};
};

export default CollapsingMenu;
