'use strict';

/**
 * Search init methods in components API and trigger them.
 *
 * @export initComponents
 * @param {Object} componentsAPI - Collection of components API.
 */
export default function initComponents( componentsAPI ) {
	for ( let componentAPIName in componentsAPI ) {
		if ( componentsAPI[ componentAPIName ].init ) {
			componentsAPI[ componentAPIName ].init();
		}
	}
}
