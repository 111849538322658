const Purchase = () => {
	const purchaseWrapper = document.querySelector( '.purchase-details' );
  if ( !purchaseWrapper ) {
    return {};
  }

  const confirmationForm = purchaseWrapper.querySelector( '#confirmation' );

	if ( !confirmationForm ) {
		return {};
	}

	const paymentOptions = confirmationForm.querySelectorAll( '.payment-option' );

	function picPaymentOption() {
		if ( !paymentOptions.length ) {
			return;
		}

		function activatePaymentOption( targetOption ) {
			Array.prototype.forEach.call( paymentOptions, option => option.classList.remove( 'payment-option--active' ) );
			targetOption.classList.add( 'payment-option--active' );

			let radio = targetOption.querySelector( 'input' );
			radio.click();
		}

		Array.prototype.forEach.call( paymentOptions, ( option ) => {
			option.addEventListener( 'click', activatePaymentOption.bind( null, option ) );
		} );
	}

	return {
		init() {
			picPaymentOption();
		}
	};
};

export default Purchase;