import SmoothScroll from 'smooth-scroll';
import { isPhoneSize } from './mobile';

/**
 * Scroll to target anchor with animation.
 *
 * @param {Object} anchor - Target node.
 * @param {Object} overrides - Options which should override defaults.
 * @param {Number} offset - Top offset value.
 */
export default function smoothScroll( anchor, offset ) {
	const scroll = new SmoothScroll();

	scroll.animateScroll( anchor, null, {
		ignore: '[data-scroll-ignore]',
		header: '.menu',
		speed: 600,
		offset: typeof( offset ) !== 'undefined' ? offset : ( isPhoneSize() ? -25 : -35 ),
		easing: 'easeInOutQuad',
	} );
}
