import { classToggler, transitionEnd } from '../_helpers';

/**
 * Control side menu component.
 */
const SiteNav = () => {
	const ROOT_ELEMENT_CLASS = 'site-nav';
	const componentElement = document.querySelector( `.${ROOT_ELEMENT_CLASS}` );
	const togglerElement = componentElement.querySelector( `.${ROOT_ELEMENT_CLASS}__toggler` );
	const coverElement = document.querySelector( '.site-cover' );
	const menuElement = document.querySelector( '.menu' );
	const sidePanel = document.querySelector( `.${ROOT_ELEMENT_CLASS}__panel` );
	const sidePanelItems = sidePanel.querySelectorAll( 'a' );
	let opened = false;

	const activeTogglerClass = `${ROOT_ELEMENT_CLASS}__toggler--active`;
	const movingTogglerClass = `${ROOT_ELEMENT_CLASS}__toggler--on-move`;
	const activeNavClass = `${ROOT_ELEMENT_CLASS}--active`;

	// Get functions for manipulating site navigation components states.
	const panelToggler = getPanelToggler();
	const coverToggler = getCoverToggler();

	/**
	 * Toggle site navigation function generator.
	 *
	 * @returns {Function} - Switching menu hamburger and site navigation panes states.
	 */
	function getSiteNavToggler( evt ) {
		evt.stopPropagation();
		const { type, keyCode, which } = evt;
		const code = keyCode || which;
		const switchOn = !togglerElement.classList.contains( activeTogglerClass );

		if ( type === 'keyup' && code !== 27 ) {
			return;
		}

		if ( switchOn ) {
			componentElement.classList.add( activeNavClass );
			togglerElement.classList.add( activeTogglerClass );
			window.addEventListener( 'keyup', getSiteNavToggler );
		} else {
			componentElement.classList.remove( activeNavClass );
			togglerElement.classList.add( movingTogglerClass );
			togglerElement.classList.remove( activeTogglerClass );
			transitionEnd( togglerElement, () => {
				setTimeout( () => {
					togglerElement.classList.remove( movingTogglerClass );
				}, 300 );
			} );
			window.removeEventListener( 'keyup', getSiteNavToggler );

			if ( menuElement.offsetTop !== 0 ) {
				if ( componentElement.classList.contains( `${ROOT_ELEMENT_CLASS}--fixed` ) ) {
					componentElement.classList.remove( `${ROOT_ELEMENT_CLASS}--fixed` );
				}
			} else {
				if ( !componentElement.classList.contains( `${ROOT_ELEMENT_CLASS}--fixed` ) ) {
					componentElement.classList.add( `${ROOT_ELEMENT_CLASS}--fixed` );
				}
			}
		}

		togglerElement.setAttribute( 'aria-expanded', switchOn );
		togglerElement.focus();
		panelToggler( switchOn );
		coverToggler( switchOn );

		if ( switchOn && !opened ) {
			switchBackToBeginEvent();
			opened = true;
		}
	}

	/**
	 * Toggle side panel function generator.
	 *
	 * @returns {Function} - Manipulating side panel active class.
	 */
	function getPanelToggler() {
		return ( show ) => {
			sidePanel.classList.toggle( `${ROOT_ELEMENT_CLASS}__panel--active` );
			sidePanel.setAttribute( 'aria-hidden', show ? 'false' : 'true' );
			Array.prototype.forEach.call( sidePanelItems, ( item ) => item.setAttribute( 'tabindex', show ? 1 : -1 ) );
		};
	}

	/**
	 * Toggle site cover function generator.
	 *
	 * @returns {Function} Manipulating site cover active class.
	 */
	function getCoverToggler() {
		const activeCoverClass = 'site-cover--active';
		const hideAnimationClass = 'site-cover--hide';

		return ( show ) => {
			if ( show ) {
				coverElement.classList.add( activeCoverClass );
			} else {
				transitionEnd( coverElement, () => {
					coverElement.classList.remove( activeCoverClass );
					coverElement.classList.remove( hideAnimationClass );
				} );
				coverElement.classList.add( hideAnimationClass );
			}
		};
	}

	/**
	 * Set keydown listener on the last site menu item to prevent leaving focus from the menu panel.
	 *
	 */
	function switchBackToBeginEvent() {
		const lists = sidePanel.querySelectorAll( '.site-nav__menu' );

		if ( !lists.length ) {
			return;
		}

		const lastList = lists[ lists.length - 1 ];
		const links = lastList.querySelectorAll( 'a' );
		const lastLink = links[ links.length - 1 ];

		lastLink.addEventListener( 'keydown', focusBackToBegin );
		togglerElement.addEventListener( 'keydown', backToEnd.bind( null, lastLink ) );
	}

	/**
	 * Goto the last menu item after press [ shift + tab ].
	 *
	 * @param {Object} lastItem - Last menu link.
	 * @param {Object} evt - Keydown event object.
	 */
	function backToEnd( lastItem, evt ) {
		if ( evt.keyCode === 9 && evt.shiftKey ) {
			evt.preventDefault();
			lastItem.focus();
		}
	}

	/**
	 * Turn back to the toggler when press on TAB key from the last menu item.
	 *
	 * @param {Object} evt - Keydown event object.
	 */
	function focusBackToBegin( evt ) {
		if ( evt.keyCode === 9 && !evt.shiftKey ) {
			evt.preventDefault();
			togglerElement.focus();
		}
	}

	// Scroll listener callback helpers.
	const originTopOffset = componentElement.offsetTop;
	const boundClassToggler = classToggler.bind( undefined, componentElement );

	return {
		/**
		 * Component initialization method which is set toggler and cover click event listeners.
		 */
		init: () => {
			// Run event listenners on component initialization.
			togglerElement.addEventListener( 'click', getSiteNavToggler );
			coverElement.addEventListener( 'click', getSiteNavToggler );
		},
		/**
		 * Callback for window scroll event.
		 *
		 * @param {Number} windowScrollTop - Current window scroll top position.
		 */
		onWindowScroll: ( windowScrollTop ) => {
			if ( !componentElement.classList.contains( `${ROOT_ELEMENT_CLASS}--active` ) ) {
				boundClassToggler( windowScrollTop >= originTopOffset, `${ROOT_ELEMENT_CLASS}--fixed` );
			}
		}
	};
};

export default SiteNav;
