'use strict';

import * as components from './_components';
import websiteListeners from './_websiteListeners';
import { getAPICollection, getEventsCallbacks, initComponents } from './_helpers/components';

import stickybits from 'stickybits';

/**
 * API root element which is triggering all listeners and initializing components.
 *
 * @returns {Object} - Website API with one init function.
 */
const Website = ( () => {
	/**
	 * Use components helpers to run website listeners with components callbacks.
	 *
	 * @param {Object} websiteListeners - Predefined collection of listeners.
	 * @param {Object} componentsAPI - Collection of public components methods keyed by component name.
	 */
	function runListeners( websiteListeners, componentsAPI ) {
		getEventsCallbacks( websiteListeners, componentsAPI )
			.then( ( eventsCallbacks ) => {
				for ( let listenerName in websiteListeners ) {
					if ( eventsCallbacks[ listenerName ] ) {
						websiteListeners[ listenerName ]( eventsCallbacks[ listenerName ] );
					}
				}
			} );
	}

	return {
		/**
		 * Website initialization function which is initializing components and set callbacks for website listeners.
		 */
		init: () => {
			const componentsAPI = getAPICollection( components );
			initComponents( componentsAPI );
			runListeners( websiteListeners, componentsAPI );

			stickybits( '.fixedsticky', { stickyBitStickyOffset: 170 } );
		}
	};
} )();

document.addEventListener( 'DOMContentLoaded', Website.init );
