import { isTabletSize, isPhoneSize } from './mobile';

/**
 * Stretch target element to 100% window height.
 *
 * @param {Object} element - Node element.
 */
export default function stretchToVH( element ) {
	if ( !isTabletSize() && !isPhoneSize() ) {
		return;
	}

	// Run calculation on the next tick;
	setTimeout( () => {
		// Check device orientation.
		const vh = Math.max( document.documentElement.clientHeight, window.innerHeight || 0 );
		element.style.height = `${vh}px`;
	}, 0 );
}
