import tingle from 'tingle.js';
import MailChimp from './MailChimp';

const { assignForm } = MailChimp();
const newsletterSource = window.location.pathname.match( /letters/ ) ? 'Letters' : 'Collaboration';

// Dialog window contents keyed by dialog type [data-dialog] attribute value.
const DIALOG_TEMPLATES = {
	newsletter: {
		content: `
			<div class='tingle-modal-box__header'><h2>Sign up to our newsletter</h2></div>
			<div class='tingle-modal-box__content-inner'>
				<form class="mc-subscribe-form" action="//ckeditor.us10.list-manage.com/subscribe/post?u=9c10324d9c722f3329b855dfd&amp;id=844b2127ac&amp;SOURCE=${newsletterSource}" method="post" name="mc-embedded-subscribe-form" target="_blank">
					<div class="module-subscribe__actions">
						<div class="form-group-inline__wrapper">
							<div class="form-group form-group-inline form-group-inline--long">
								<label class="sr-only" for="mc-modal-EMAIL">Company e-mail address</label>
								<input class="form-control email" type="email" placeholder="Your company email" name="EMAIL" id="mc-modal-EMAIL" required>
							</div>
							<div class="form-group form-group-inline">
								<button class="btn btn--big" name="subscribe" type="submit">Subscribe</button>
							</div>
							<input type="hidden" name="b_9c10324d9c722f3329b855dfd_844b2127ac" tabindex="-1" value="" aria-hidden="true">
						</div>
					</div>
					<div class="mc-response"></div>
				</form>
			</div>
		`
	},
	webspellchecker: {
		content: `
			<div class='tingle-modal__header'><h2>WebSpellChecker</h2></div>
			<div class='tingle-modal-box__content-inner'>
				<p>Spell checking functionality is a third-party service included in CKEditor 4 and provided by our partners at WebSpellChecker.</p>
				<p>For more information please visit their website at <a href="https://www.webspellchecker.net/" target="_blank" rel="noopener noreferrer">WebSpellChecker.net</a> and see the overview of this feature in an <a href="https://ckeditor.com/docs/ckeditor4/latest/guide/dev_spellcheck.html" target="_blank">online sample</a>.</p>
			</div>
		`,
		cssClasses: [ 'tingle-modal--info' ]
	},
	'webspellchecker-extended': {
		content: `
			<div class='tingle-modal__header'><h2>WebSpellChecker</h2></div>
			<div class='tingle-modal-box__content-inner'>
				<p>Spell checking functionality is a third-party service included in CKEditor 4 and provided by our partners at WebSpellChecker.</p>
				<p>For more information please visit their website at <a href="https://www.webspellchecker.net/" target="_blank" rel="noopener noreferrer">WebSpellChecker.net</a> and see the overview of this feature in an <a href="https://ckeditor.com/docs/ckeditor4/latest/guide/dev_spellcheck.html" target="_blank">online sample</a>.</p>
			</div>
		`,
		footer: `<p>Please visit the <a href="https://www.webspellchecker.net/pricing.html" target="_blank" rel="noopener noreferrer">WebSpellChecker pricing page</a> for details.</p>`,
		cssClasses: [ 'tingle-modal--info', 'tingle-modal--with-footer' ]
	}
};

/**
 * Display tingle dialog with defined by [data-dialog] attribute template.
 */
const Dialog = () => {
	/**
	 * Move close button from the outside of dialog box into it.
	 */
	function injectCloseBtnsIntoDialog() {
		const tingleEl = document.querySelector( '.tingle-modal--visible' );

		if ( !tingleEl || tingleEl.querySelector( '.tingle-modal-box .tingle-modal__close' ) ) {
			return;
		}

		const tingleBox = tingleEl.querySelector( '.tingle-modal-box' );
		const closeBtn = tingleEl.querySelector( '.tingle-modal__close' );

		const btnFragmentContainer = document.createDocumentFragment();
		btnFragmentContainer.appendChild( closeBtn );

		tingleBox.appendChild( btnFragmentContainer );
	}

	/**
	 * Init tingle module with template marked by type.
	 *
	 * @param {String} type - Target dialog template.
	 */
	function modalInit( type ) {
		if ( !DIALOG_TEMPLATES[ type ] ) {
			return console.error( 'Wrong template name' );
		}

		const { content, footer, cssClasses } = DIALOG_TEMPLATES[ type ];
		const modal = new tingle.modal( {
			closeMethods: [ 'escape', 'button', 'overlay' ],
			footer: footer ? true : false,
			cssClass: cssClasses || [],
			onOpen() {
				injectCloseBtnsIntoDialog();

				if ( type === 'newsletter' ) {
					// Add submit handle to prevent default MailChimp form behaviour.
					assignForm( document.querySelector( '.tingle-modal-box .mc-subscribe-form' ) );
				}
			},
			onClose() {
				modal.destroy();
			}
		} );

		modal.setContent( content );

		if ( footer ) {
			modal.setFooterContent( footer );
		}

		modal.open();
	}

	/**
	 * Create dialog element on click on the element with [data-dialog] attribute.
	 */
	function activateDialogTriggers() {
		const triggers = document.querySelectorAll( '[data-dialog]' );
		Array.prototype.forEach.call( triggers, trigger => trigger.addEventListener( 'click', ( evt ) => {
			// Prevent window scrolling to the top.
			if ( trigger.hasAttribute( 'data-dialog-prevent' ) ) {
				evt.preventDefault();
			}

			modalInit( trigger.getAttribute( 'data-dialog' ) );
		} ) );
	}

	return {
		init: activateDialogTriggers
	};
};

export default Dialog;
