import smoothScroll from '../_helpers/smoothScroll';

/**
 * Handle MC forms submit.
 */
const MailChimp = () => {
	const mailChimpForms = document.querySelectorAll( '.mc-subscribe-form' );
	const subscribeSectionLinks = document.querySelectorAll( '[data-goto-subscribe]' );

	/**
	 * Serialize form inputs data.
	 *
	 * @param {Object} form - Node of requested form.
	 * @returns {String} - Serialized form data.
	 */
	function serialize( form ) {
		let serialized = '';

		for ( let i = 0; i < form.elements.length; i++ ) {
			const field = form.elements[ i ];

			if (
				!field.name ||
				field.disabled ||
				field.type === 'submit' ||
				field.type === 'button' ||
				( field.type === 'checkbox' && !field.checked ) ) {
				continue;
			}

			if ( field.type === 'checkbox' ) {
				field.value = 'Y';
			}

			serialized += '&' + encodeURIComponent( field.name ) + "=" + encodeURIComponent( field.value );
		}

		return serialized;
	}

	/**
	 * Created GET request URL based on form action. Added reference to injected callback action.
	 *
	 * @param {Object} form - Node of requested form.
	 * @returns {String} - New created URL with JSONP parameters.
	 */
	function getAjaxSubmitUrl( form ) {
		return form.getAttribute( 'action' )
				.replace( '/post?u=', '/post-json?u=' )
			+ serialize( form )
			// Agree for GPDR
			+ '&gdpr[1]=Y'
			+ '&c=submitCallback';
	}

	/**
	 * Inject JSONP reqest callback.
	 */
	function injectCallback() {
		const scriptEl = document.createElement( 'script' );

		scriptEl.innerHTML = `
			function submitCallback( data ) {
				var pendingForm = document.querySelector( '[data-pending]' );
				var responseWrapper = pendingForm.querySelector( '.mc-response' );
				// Clenup classes (error, success).
				responseWrapper.className = 'mc-response';

				// Cleanup old messages.
				while ( responseWrapper.firstChild ) {
					responseWrapper.removeChild( responseWrapper.firstChild );
				}

				var response = document.createElement( 'p' );

				function _checkIfAlreadyExistsMsg( msg ) {
					return /is already subscribed to list/.test( msg );
				}

				function _replaceAlreadyOnListMsg( msg ) {
					if ( !_checkIfAlreadyExistsMsg( msg ) ) {
						return msg;
					}

					let hrefStart = 'href="';
					let updateDataLink = msg.substring( msg.indexOf( hrefStart ) + hrefStart.length, msg.indexOf( '">' ) );
					let msgEmailPart = msg.substring( 0, msg.indexOf( ' <a' ) );

					return msgEmailPart + ' To manage your preferences please go to ' + '<a href="' + updateDataLink + '">link</a>.';
				}

				response.innerHTML = _replaceAlreadyOnListMsg( data.msg );

				if ( data.result === 'success' ) {
					var formInputsWrappers = pendingForm.querySelectorAll( '.form-part-hidden-after-subscribe' );

					Array.prototype.forEach.call( formInputsWrappers, function( wrapper ) {
						wrapper.parentElement.removeChild( wrapper );
					} );

					responseWrapper.classList.add( 'mc-response--success' );

					// Close newsletter dialog after 5 sek.
					setTimeout( function() {
						var tingleModal = document.querySelector( '.tingle-modal--visible' );

						if ( tingleModal ) {
							var clickEvt = new MouseEvent( 'click' );
							tingleModal.querySelector( '.tingle-modal__close' ).dispatchEvent( clickEvt );
						}
					}, 5000 );
				} else {
					responseWrapper.classList.add( _checkIfAlreadyExistsMsg( data.msg ) ? 'mc-response--success' : 'mc-response--error' );
				}

				responseWrapper.appendChild( response );
				pendingForm.removeAttribute( 'data-pending' );
			}
		`;

		document.body.appendChild( scriptEl );
	}

	/**
	 * Inject script to head which is get request add subscriber action.
	 *
	 * @param {Object} form - Node subscribe form.
	 * @param {Object} evt - Submit form event.
	 */
	function addSubscriber( form, evt ) {
		evt.preventDefault();

		form.setAttribute( 'data-pending', 'true' );

		const scriptEl = document.createElement( 'script' );
		scriptEl.src = getAjaxSubmitUrl( form );
		document.head.appendChild( scriptEl );

		scriptEl.addEventListener( 'load', () => document.head.removeChild( scriptEl ) );
	}

	/**
	 * Smooth scroll to the subscribe section.
	 *
	 * @param {Object} evt - Mouse click event.
	 */
	function gotoSubscribeSection( evt ) {
		evt.preventDefault();

		const subscribeSection = document.getElementById( evt.target.href.split( '#' )[1] );

		smoothScroll( subscribeSection, 0 );
		subscribeSection.querySelector( 'input[type="email"]' ).focus();
	}

	return {
		init() {
			Array.prototype.forEach.call( mailChimpForms, ( form ) => {
				form.addEventListener( 'submit', addSubscriber.bind( null, form ) );
			} );

			Array.prototype.forEach.call( subscribeSectionLinks, ( link ) => {
				link.addEventListener( 'click', gotoSubscribeSection );
			} );

			injectCallback();
		},
		assignForm( form ) {
			form.addEventListener( 'submit', addSubscriber.bind( null, form ) );
		}
	};
};

export default MailChimp;
