'use strict';

/**
 * Detect which event name is corectly for the browser.
 *
 * @returns {String} - Name of transition event.
 */
function whichTransitionEvent() {
	const el = document.createElement( 'fakeelement' );
	const transitions = {
		'transition': 'transitionend',
		'OTransition': 'oTransitionEnd',
		'MozTransition': 'transitionend',
		'WebkitTransition': 'webkitTransitionEnd'
	};

	for ( let t in transitions ) {
		if ( el.style[ t ] !== undefined ) {
			return transitions[ t ];
		}
	}
}

/**
 * Trigger callback function when transition on element is end.
 *
 * @export transitionEnd
 * @param {Object} element - DOM element which will be target for listener.
 * @param {Function} callback - Callback function triggered after animation.
 * @return {Function} - Remove listener function.
 */
export default function transitionEnd( element, callback ) {
	const transitionEvent = whichTransitionEvent();
	const eventCallback = () => {
		callback();
		element.removeEventListener( transitionEvent, eventCallback );
	};
	element.addEventListener( transitionEvent, eventCallback );
}
