export default function ObservableSubject() {
	let handlers = [];

	return {
		subscribe( fn ) {
			handlers.push( fn );
		},
		update( newValue ) {
			this.fire( newValue );
		},
		fire( newValue ) {
			handlers.forEach( fn => fn( newValue ) );
		}
	};
}
