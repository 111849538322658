import tingle from 'tingle.js';

const ImageDialog = () => {
	const imageModal = new tingle.modal( {
		closeMethods: [ 'escape', 'button', 'overlay' ],
		footer: false
	} );

	function openDialog( imageURL, evt ) {
		evt.preventDefault();

		imageModal.setContent( createModalContent( imageURL ) );
		imageModal.open();
	}

	function createModalContent( img ) {
		return `
		<div class="image-dialog__wrapper image-dialog__wrapper--with-link">
			<img src="${img}" />
		</div>
		`;
	}

	function activateDialogTriggers() {
		const triggers = document.querySelectorAll( '[data-image-dialog]' );

		Array.prototype.forEach.call( triggers, trigger =>
			trigger.addEventListener( 'click', openDialog.bind( null, trigger.getAttribute( 'data-image-dialog' ) ) )
		);
	}

	return {
		init: activateDialogTriggers
	};
};

export default ImageDialog;
